<template>
    <div class="solve-container">
        <a-card title="解决方案管理">
            <div class="tool">
                <div>
                    <a-input-search style="width: 250px;margin-right: 10px" v-model="keyword" placeholder="搜索" enter-button @search="onSearch" allowClear/>
                    <a-range-picker :locale="locale" @change="pickerChange" style="margin-right: 10px"/>
                </div>
                <a-button type="primary" icon @click="add"><a-icon type="plus"></a-icon>新增</a-button>
            </div>
            <div class="table">
                <a-table :rowKey="(item,i)=>item.id" :columns="columns" :data-source="tableData" :pagination="pagination" :loading="loading"></a-table>
            </div>
        </a-card>
        <a-modal :width="800" v-model="editWindowShow" :title="modalTitle" @ok="modalOk" cancelText="取消" okText="确定" :confirm-loading="confirmLoading">
            <a-form-model ref="form" :rules="formRules" :model="formData" :label-col="{span:5}" :wrapper-col="{span:12}">
                <a-form-model-item label="行业名称：" required prop="name">
                    <a-input v-model="formData.name" placeholder="请输入行业名称"></a-input>
                </a-form-model-item>
                <a-form-model-item  label="行业痛点：" required prop="scenario">
                    <div>
                        <div v-for="(domain, index) in dynamicValidateForm.domains" :key="index">
                            <a-input  v-model="domain.painPoint" placeholder="请输入痛点" style="width: 150px; margin-right: 8px"/>
                            <a-icon
                                v-if="dynamicValidateForm.domains.length > 1"
                                class="dynamic-delete-button"
                                type="minus-circle-o"
                                :disabled="dynamicValidateForm.domains.length === 1"
                                @click="removeDomain(domain)"
                            />
                            <a-input v-model="domain.painPointContent" type="textarea" :row="3" placeholder="请输入痛点概述"></a-input>
                        </div>
                        <a-button type="dashed" style="width: 120px" @click="addDomain"><a-icon type="plus" />添加</a-button>
                    </div>
                    <br/>
                </a-form-model-item>
                <a-form-model-item required label="方案名称：" prop="planName">
                    <a-input v-model="formData.planName" plalceholder="请输入方案名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="方案描述：" required prop="planIntroduce">
                    <a-textarea v-model="formData.planIntroduce" plalceholder="请输入方案描述" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
                </a-form-model-item>
                <a-form-model-item label="方案图片："  prop="planImg">
                    <upload ref="uploadProject" action="" upload-length="6" @uploadChange="handleChange"></upload>
                </a-form-model-item>
                <a-form-model-item label="实施内容：" required prop="execute">
                    <a-textarea v-model="formData.execute" plalceholder="请输入实施内容" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
                </a-form-model-item>
                <a-form-model-item label="实施图片："  prop="executeImg">
                    <upload ref="uploadExecute" action="" upload-length="6" @uploadChange="handleExecuteUploadChange"></upload>
                </a-form-model-item>
                <a-form-model-item label="应用场景：" required prop="application">
                    <a-textarea v-model="formData.application" plalceholder="请输入应用场景" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
                </a-form-model-item>
                <a-form-model-item label="应用场景图片："  prop="applicationImg">
                    <upload ref="uploadApplyScene" action="" upload-length="6" @uploadChange="handleApplySceneUploadChange"></upload>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>

</template>

<script>
import {h} from "vue";
import upload from "@/components/upload.vue";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import {saveOrEditSolution, selectSolution,deleteSolution} from '@/api/apis'
import handleRequest from "@/utils/handleRequest";
export default {
    components:{upload},
    name: "solveOption",
    data() {
        return {
            locale,
            keyword:'',
            columns:[
                {
                    title: '序号',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    customRender: (text,record,index) => `${index+1}`,
                },
                {
                    align: 'center',
                    dataIndex:'planName',
                    key:'planName',
                    title:'方案名称',
                },
                {
                    align: 'center',
                    dataIndex:'createTime',
                    key:'createTime',
                    title:'添加时间',
                },
                {
                    align: 'center',
                    dataIndex:'state',
                    key:'state',
                    title:'状态',
                    customRender:(text, record, index)=> {
                        return  h("a-switch",{
                            props:{
                                checked:record.state == 2 ? false:true
                            },
                            on:{
                                'change':()=>{
                                    this.switchChange(record)
                                }
                            }
                        })
                    }
                },
                {
                    align: 'center',
                    dataIndex:'action',
                    key:'action',
                    title:'操作',
                    width:200,
                    customRender:(text,record,index)=>{
                        return h("div",[
                            h("a-button", {
                                style:{
                                    marginRight:'10px'
                                },
                                props:{
                                    icon:'edit',
                                    type:'primary',
                                    size:'small'
                                },
                                on:{
                                    click:()=>{
                                        this.edit(record)
                                    }
                                }
                            }, '编辑'),
                            h("a-popconfirm",{
                                props:{
                                    title:'确定要删除吗？',
                                    okText:'确定',
                                    cancelText:'取消',
                                },
                                on:{
                                    'confirm':()=>{
                                        this.delete(record)
                                    }
                                }
                            },[
                                h("a-button",{
                                    props:{
                                        icon:'delete',
                                        type: 'danger',
                                        size:'small',
                                    }
                                },'删除')
                            ])
                        ])
                    }
                },
            ],
            tableData:[],
            editWindowShow:false,
            modalTitle:'',
            dynamicValidateForm:{
                domains:[{
                    painPoint:'',
                    painPointContent:''
                }]
            },
            loading:false,
            confirmLoading:false,
            pagination:{
                pageSize:10,
                current:1,
                total:0,
                showTotal:(total)=>`共${total}条`,
                onChange:(page,pageSize)=>this.pageChange(page,pageSize)
            },
            formRules: {
                name: [{required:true,message:'请输入行业名称',trigger:'blur'}],
                scenario: [{validator:(rules,value,callback)=>{
                    if(this.formData.scenario.length){
                        if(this.formData.scenario[0].painPoint){
                            return true
                        }else{
                            callback('请输入行业痛点')
                        }
                    }else{
                        callback('请添加行业痛点')
                    }
                    },trigger:'blur'}],
                planName: [{required:true,message:'请输入方案名称',trigger:'blur'}],
                planIntroduce: [{required:true,message:'请输入方案描述',trigger:'blur'}],
                // planImg: [{validator: (rule,value,callback)=>{
                //         if(!this.formData.planImg.length){
                //             callback('请至少上传一张')
                //         }else{
                //             return true
                //         }
                //     },trigger:'blur'}],
                execute: [{required:true,message:'请输入方案描述',trigger:'blur'}],
                // executeImg: [{validator: (rule,value,callback)=>{
                //         if(!this.formData.executeImg.length){
                //             callback('请至少上传一张')
                //         }else{
                //             return true
                //         }
                //     },trigger:'blur'}],
                application:[{required:true,message:'请输入应用场景',trigger:'blur'}],
                // applicationImg: [{validator: (rule,value,callback)=>{
                //         if(!this.formData.applicationImg.length){
                //             callback('请至少上传一张')
                //         }else{
                //             return true
                //         }
                //     },trigger:'blur'}],
            },
            formData:{
                sort:'',
                name:'',
                scenario:[
                    {
                        painPoint:''
                    },
                ],
                planName:'',
                planIntroduce:'',
                planImg:[],
                execute:'',
                executeImg:[],
                application:'',
                applicationImg:[],
                state:2
            }
        }
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        async getTableData(startTime,endTime){
            let params = {
                name:this.keyword,
                page:this.pagination.current,
                pageSize: this.pagination.pageSize,
                startTime:startTime || '',
                endTime:endTime || ''
            }
            const data = handleRequest(await selectSolution(params))
            this.tableData = data.content
        },
        handleChange(imgList){
            this.formData.planImg = imgList
        },
        handleExecuteUploadChange(imgList){
            this.formData.executeImg = imgList
        },
        handleApplySceneUploadChange(imgList){
            this.formData.applicationImg = imgList
        },
        addDomain() {
            if(this.dynamicValidateForm.domains.length>=3){
                this.$message.error('至多3条');
                return
            }else{
                this.dynamicValidateForm.domains.push({
                    painPoint: '',
                    painPointContent: '',
                });
            }
        },
        removeDomain(item) {
            let index = this.dynamicValidateForm.domains.indexOf(item);
            if (index !== -1) {
                this.dynamicValidateForm.domains.splice(index, 1);
            }
        },
        pickerChange(date,dateString){
            console.log(dateString)
            this.getTableData(dateString[0],dateString[1])
        },
        onSearch(){
            this.getTableData()
        },
        async switchChange(params){
            console.log(params)
            params.state = params.state == 1 ? false:true
            let switchedData = {...params}
            // switchedData.scenario = JSON.stringify(switchedData.scenario)
            // switchedData.planImg = JSON.stringify(switchedData.planImg)
            // switchedData.executeImg = JSON.stringify(switchedData.executeImg)
            // switchedData.applicationImg = JSON.stringify(switchedData.applicationImg)
            switchedData.state = switchedData.state == true ? 1:2
            console.log(switchedData)
            const res = await saveOrEditSolution(switchedData)
            handleRequest(res,(res)=>{
                this.$message.success('切换成功')
                this.getTableData()
            })
        },
        edit(params){
            this.editWindowShow = true
            this.modalTitle = '编辑'
            this.formData = params
            if(typeof this.formData.scenario == "string"){
                this.formData.scenario = JSON.parse(this.formData.scenario)
                this.formData.planImg = JSON.parse(this.formData.planImg)
                this.formData.executeImg = JSON.parse(this.formData.executeImg)
                this.formData.applicationImg = JSON.parse(this.formData.applicationImg)
            }
            this.dynamicValidateForm.domains = this.formData.scenario
            this.$nextTick(()=>{
                this.$refs.uploadProject.imgList = this.formData.planImg
                this.$refs.uploadExecute.imgList = this.formData.executeImg
                this.$refs.uploadApplyScene.imgList = this.formData.applicationImg
            })
        },
        async delete(params){
            handleRequest(await deleteSolution({id:params.id}),res=>{
                if(res.code == 200){
                    this.$message.success('操作成功')
                }
            })
        },
        add(){
            this.editWindowShow = true
            this.modalTitle = '新增'
            this.dynamicValidateForm.domains = [{
                painPoint:''
            }]
            this.$nextTick(()=>{
                this.$refs.uploadApplyScene.imgList = []
                this.$refs.uploadExecute.imgList = []
                this.$refs.uploadProject.imgList = []
            })
            this.formData = {
                sort:'',
                name:'',
                scenario:[
                    {
                        painPoint:''
                    },
                ],
                planName:'',
                planIntroduce:'',
                planImg:[],
                execute:'',
                executeImg:[],
                application:'',
                applicationImg:[],
                state:2
            }
        },
        modalOk(){
            console.log('编辑窗口确定')
            this.formData.scenario = this.dynamicValidateForm.domains
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.formData.scenario = JSON.stringify(this.formData.scenario)
                    this.formData.planImg = JSON.stringify(this.formData.planImg)
                    this.formData.executeImg = JSON.stringify(this.formData.executeImg)
                    this.formData.applicationImg = JSON.stringify(this.formData.applicationImg)
                    handleRequest(await saveOrEditSolution(this.formData),res=>{
                        if(res.code == 200){
                            this.$message.success('操作成功')
                            this.confirmLoading = false
                            this.editWindowShow = false
                            this.getTableData()
                        }
                    })
                } else {
                    console.log('验证错误');
                    console.log(this.formData)
                    return false;
                }
            });
        },
    },
}
</script>

<style scoped lang="scss">
.solve-container{
    padding: 20px;
    .tool{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }
    .table{
        margin-top: 30px;
    }
}
</style>
