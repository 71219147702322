<template>
    <div class="clearfix">
        <a-upload
            list-type="picture-card"
            :file-list="imgList"
            name="files"
            @preview="handlePreview"
            :customRequest="upload"
            :remove="remove"
        >
            <div v-if="imgList?.length < uploadLength">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传</div>
            </div>
        </a-upload>
        <a-modal width="1200px" :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%;" :src="previewImage" />
        </a-modal>
    </div>
</template>

<script>
import {fileUpload} from '@/api/apis'
export default {
    name: "upload",
    props:['uploadLength'],
    data() {
        return {
            imgList:[],
            previewVisible:false,
            previewImage:'',
        }
    },
    mounted() {
    },
    methods: {
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        handleCancel() {
            this.previewVisible = false;
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await this.getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        remove(file){
            this.imgList.forEach((item,i)=>{
                if(item.uid === file.uid){
                    this.imgList.splice(i,1)
                }
            })
            this.$emit('remove',this.imgList)
        },
        upload({file}){
            let formData = new FormData()
            formData.append('files',file)
            fileUpload(formData).then(res=>{
                this.imgList.push({
                    uid:file.uid,
                    url:this.$api.imgBaseURL()+res.data.fileName,
                    name:file.name,
                    status: 'done',
                    percent:100,
                })
                this.$emit('uploadChange',this.imgList)
            })
        },
    },
}
</script>

<style scoped lang="scss">

</style>
